import {
  T,
  always,
  applySpec,
  converge,
  curry,
  divide,
  equals,
  filter,
  identity,
  ifElse,
  lensPath,
  map,
  pathOr,
  pipe,
  pluck,
  prop,
  propOr,
  reject,
  set,
  sum,
  whereEq,
} from 'ramda'

import round from 'utils/round'

const toFixed1 = value =>
  !isNaN(parseFloat(value)) && isFinite(value) ? value.toFixed(1) : value
// UTILS - LENSES
const dataLens = lensPath(['classUsage', 'data'])
const gradeLens = lensPath(['context', 'availableGrades'])
const recordsProp = pathOr([], ['payload', 'response', 'records'])
const gradesProp = pathOr([], ['payload', 'response', 'availableGrades'])
const selectWorkingStats = pipe(
  pathOr([], ['stats', 'lesson_completion', 'v1', 'stats']),
  reject(
    pipe(
      prop('status'),
      equals('not_started')
    )
  )
)

// UTILS
const sumCounts = pipe(
  pluck('count'),
  sum
)

const lessonsFilteredBy = curry(predicate =>
  pipe(
    selectWorkingStats,
    filter(predicate),
    sumCounts
  )
)

// UTILS - STATS SPEC
export const studentCount = pathOr(0, [
  'stats',
  'performance',
  'v1',
  'num_students',
])

const countPerStudentBy = curry(predicate => {
  const noStudents = pipe(
    studentCount,
    equals(0)
  )
  const lessonsPerStudent = pipe(
    converge(divide, [lessonsFilteredBy(predicate), studentCount]),
    round(1)
  )

  return pipe(
    ifElse(noStudents, always(0)),
    toFixed1
  )(lessonsPerStudent)
})

/* eslint-disable camelcase */
export const averageQuickCercaLessons = countPerStudentBy(
  whereEq({ lesson_type: 3 })
)
export const averageAppliedLessons = countPerStudentBy(
  whereEq({ lesson_type: 1 })
)
export const averageProgressMonitoringLessons = countPerStudentBy(
  whereEq({ lesson_type: 7 })
)
export const averageInstaCercaLessons = countPerStudentBy(
  whereEq({ lesson_type: 4 })
)
export const averageMiniLessons = countPerStudentBy(whereEq({ lesson_type: 2 }))
export const averageSkillLessons = countPerStudentBy(
  whereEq({ lesson_type: 8 })
)
export const averageTotalLessons = countPerStudentBy(T)
export const totalLessons = lessonsFilteredBy(T)
export const totalWritingLessonsGraded = lessonsFilteredBy(
  whereEq({ status: 'graded' })
)
export const totalWritingLessonsThatNeedGraded = lessonsFilteredBy(
  whereEq({ status: 'needs_grading' })
)

const classUsageSpec = applySpec({
  rowHeaderProps: {
    gradeLevel: propOr('', 'gradeLevel'),
    subject: propOr('', 'subject'),
    teacher: propOr('', 'teacher'),
    title: propOr('', 'title'),
    courseCode: propOr('', 'courseCode'),
    id: prop('id'),
  },
  id: prop('id'),
  studentCount,
  averageQuickCercaLessons,
  averageAppliedLessons,
  averageProgressMonitoringLessons,
  averageInstaCercaLessons,
  averageMiniLessons,
  averageSkillLessons,
  averageTotalLessons,
  totalLessons,
  totalWritingLessonsGraded,
  totalWritingLessonsThatNeedGraded,
})

// HANDLE ACTIONS
const setIsFetching = set(lensPath(['classUsage', 'isFetching']))
const loadResponse = action =>
  pipe(
    set(dataLens, map(classUsageSpec, recordsProp(action))),
    set(gradeLens, gradesProp(action))
  )

export const initialState = {
  isFetching: false,
  data: [],
  filter: 'ALL_GRADES_FILTER',
}

// REDUCERS
// eslint-disable-next-line complexity
export default (state = initialState, action = {}) => {
  switch (action.status) {
    case 'init':
      return setIsFetching(true)(state)
    case 'success':
      return pipe(
        setIsFetching(false),
        loadResponse(action)
      )(state)
    case 'error':
      return setIsFetching(false)(state)
    default:
      return identity(state)
  }
}

import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import emptyOrNil from 'utils/emptyOrNil'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import { SchemaLink } from 'apollo-link-schema'
import { any, cond, not, pathEq } from 'ramda'

import {
  makeExecutableSchema,
  makeRemoteExecutableSchema,
  mergeSchemas,
} from 'graphql-tools'

import appTypeDefs from 'thinkcerca-schema.graphql'
import csrfToken from 'utils/csrf-token'
import signupTypeDefs from 'modules/registration/schema.graphql'
import cacheErrorQuery from 'components/ServerErrorNotification/query.graphql'
import { UNPROTECTED_ROUTES } from 'constants/unprotectedRoutes'

const buildInterfaceIntrospectionInfo = (name, typeNames) => {
  return {
    kind: 'INTERFACE',
    name,
    possibleTypes: typeNames.map(name => ({ name })),
  }
}

export const createCache = () => {
  let fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'UNION',
            name: 'AssignableContext',
            possibleTypes: [{ name: 'SchoolClass' }, { name: 'StudentGroup' }],
          },
          buildInterfaceIntrospectionInfo('CSKLessonStepInterface', [
            'Slide',
            'Annotations',
            'CompareExamples',
            'HeadlineImageText',
            'MultiColumnLearn',
            'NumberedList',
            'ClozeFillBlanks',
            'CskHighlight',
            'Matrix',
            'MultipleChoice',
            'MultipleChoiceLongAnswer',
            'MultipleChoiceLongPassage',
            'LessonScore',
          ]),
          buildInterfaceIntrospectionInfo('AdditionalInfoable', [
            'FlexibleLessonPage',
            'TextResponseField',
            'LinkedResponseField',
            'ShortTextResponseField',
            'SupportingShortTextResponseFieldGroup',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleLessonPageable', [
            'FlexibleLesson',
            'FlexibleLessonPageGroup',
            'FlexibleLessonSection',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleLessonPageGroupable', [
            'FlexibleLesson',
            'FlexibleLessonSection',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleLessonOutlineable', [
            'FlexibleLessonPage',
            'FlexibleLessonSection',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleLessonSectionOutlineable', [
            'FlexibleLessonPage',
            'FlexibleLessonPageGroup',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleLessonPageOutlineable', [
            'PageSourceMaterial',
            'WritingPrompt',
            'VocabularyList',
            'ShortTextResponseField',
            'TopicOverview',
            'TextResponseField',
            'LinkedResponseField',
            'MultipleChoiceResponseField',
            'SourceMaterialAnnotation',
          ]),
          buildInterfaceIntrospectionInfo('SentenceStarterable', [
            'AdditionalInfo',
          ]),
          buildInterfaceIntrospectionInfo('LinkedResponseFieldable', [
            'TextResponseField',
          ]),
          buildInterfaceIntrospectionInfo('FlexibleStudentRespondable', [
            'TextResponseField',
            'MultipleChoiceQuestion',
            'ShortTextResponseField',
            'SourceMaterialAnnotation',
          ]),
        ],
      },
    },
  })

  let cache = new InMemoryCache({ fragmentMatcher })
  cache.writeData({ data: { errors: [] } }) // hydrate "errors" to avoid warning
  return cache
}

export const cache = createCache()

const graphQLErrorsPresent = ({ graphQLErrors }) =>
  not(emptyOrNil(graphQLErrors))

const networkErrorPresent = ({ networkError }) => not(emptyOrNil(networkError))

const notifyOnError = errors => {
  cache.writeQuery({
    query: cacheErrorQuery,
    data: {
      errors: errors.map(error => ({
        __typename: 'NetworkError',
        message: error.message,
        statusCode: error['statusCode'] || null,
      })),
    },
  })
}

const logOnError = (err, msg, context = {}) => {
  if (console) console.error(msg, err.message) // eslint-disable-line no-console

  if (window.Honeybadger) window.Honeybadger.notify(err, { context })
}

// custom errors from Graphql::Error and GraphQL::Schema::Timeout are equipped
// with "extensions.handling" that suggests what to do.
const handleGraphQLErrors = ({ graphQLErrors, response, operation }) => {
  // Unknown errors may lead to inconsistent state, nicely ask the user to start over.
  if (any(pathEq(['extensions', 'handling'], 'notify'), response.errors)) {
    notifyOnError(response.errors)
  }
  // error will have "handling" of 'pass' for server timeouts. Un-setting
  // response.errors allows Apollo to continue with subsequent requests.
  if (any(pathEq(['extensions', 'handling'], 'pass'), response.errors)) {
    console.warn('graphQL error will be silently ignored.') // eslint-disable-line no-console
    response.errors = null
  }
  graphQLErrors.map(error => {
    logOnError(error, `graphQL error at ${error.path}: `, {
      response,
      operation,
    })
  })
}

const handleNetworkIssue = ({ networkError }) => {
  if (networkError.statusCode == 401) {
    // Unauthorized. Bounce to login.
    if (!UNPROTECTED_ROUTES.includes(window.location.pathname)) {
      window.location = '/'
    }
  } else {
    logOnError(networkError, 'API returned unusable response: ', {
      status: networkError.statusCode,
      response: networkError.response,
      body: networkError.bodyText,
    })
    notifyOnError([networkError])
  }
}

const errorLink = onError(
  cond([
    [graphQLErrorsPresent, handleGraphQLErrors],
    [networkErrorPresent, handleNetworkIssue],
  ])
)

const buildLinkedSchema = (uri, typeDefs) => {
  const httpLink = createHttpLink({
    uri,
    credentials: 'same-origin',
  })

  const contextLink = setContext(() => ({
    headers: {
      'X-CSRF-Token': csrfToken(),
    },
  }))

  const link = ApolloLink.from([contextLink, errorLink, httpLink])

  const schema = makeExecutableSchema({
    typeDefs,
    resolverValidationOptions: { requireResolversForResolveType: false },
  })

  return makeRemoteExecutableSchema({ schema, link })
}

const schema = mergeSchemas({
  schemas: [
    buildLinkedSchema('/graphql', appTypeDefs),
    buildLinkedSchema('/signup', signupTypeDefs),
  ],
})

const schemaLink = new SchemaLink({ schema })

// global state FTW. Manu said it was OK.
const countdownLink = setContext(() => {
  window.lastNetworkRequest = Date.now()
})

const link = countdownLink.concat(schemaLink)

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: process.env.NODE_ENV === 'development',
  resolvers: {},
})

export default client

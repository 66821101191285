import { __, evolve } from 'ramda'

import deepMerge from 'utils/deep-merge'

export const initialState = { currentlyUpdating: {} }

export default (state = initialState, { currentlyUpdating }) =>
  evolve({
    currentlyUpdating: deepMerge(__, currentlyUpdating),
  })(state)

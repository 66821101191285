import {
  find,
  findIndex,
  lensIndex,
  lensPath,
  lensProp,
  propEq,
  set,
  view,
} from 'ramda'

import { InitialState } from './InitialState'

export default function setSaving(state = InitialState, { recordName, id }) {
  const recordLens = lensPath(['records', recordName])
  const records = view(recordLens, state)
  const recordIndexLens = lensIndex(findIndex(propEq('id', id), records))
  const record = find(propEq('id', id), records)
  const savingRecord = set(lensProp('saving'), true, record)

  return set(recordLens, set(recordIndexLens, savingRecord, records), state)
}

import createReducer from 'utils/create-reducer'

import ActionTypes from '../constants/actionTypes'

export const initialState = {
  passwordDialog: {
    show: false,
  },
}

const handlers = {
  [ActionTypes.SHOW_PASSWORD_DIALOG]: showPasswordDialog,
  [ActionTypes.HIDE_PASSWORD_DIALOG]: hidePasswordDialog,
}

export default createReducer(initialState, handlers)

export function showPasswordDialog(state, { payload: { student } }) {
  return {
    ...state,
    passwordDialog: {
      show: true,
      student,
    },
  }
}

export function hidePasswordDialog(state) {
  return {
    ...state,
    passwordDialog: { show: false },
  }
}

/* eslint-env node */
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { initializeCurrentLocation } from 'redux-little-router'
import thunk from 'redux-thunk'
import { reducer as formReducer } from 'redux-form'

import createReducers from 'reducers'

import * as router from '../router/config'

const otherReducers = {
  form: formReducer,
  router: router.reducer,
}

export default function configureStore() {
  const enhancer = composeWithDevTools(
    router.enhancer,
    applyMiddleware(thunk, router.middleware)
  )

  const store = createStore(createReducers(otherReducers), enhancer)

  const initialLocation = store.getState().router

  if (initialLocation) {
    store.dispatch(initializeCurrentLocation(initialLocation))
  }

  if (module.hot) {
    module.hot.accept(['../reducers', '../router/config'], () => {
      // eslint-disable-next-line global-require
      const nextReducer = require('../reducers').default(otherReducers)

      store.replaceReducer(nextReducer)
    })
  }

  return store
}

import {
  always,
  concat,
  defaultTo,
  flip,
  identity,
  ifElse,
  length,
  lensPath,
  lensProp,
  map,
  mergeRight,
  over,
  pathOr,
  pipe,
  prop,
  propOr,
  uniqBy,
  __,
} from 'ramda'

import camelize from 'utils/camelize'

const concatWith = (withFn, collectionRecords) =>
  ifElse(
    always(length(collectionRecords)),
    pipe(
      defaultTo([]),
      concat(map(withFn, collectionRecords)),
      uniqBy(prop('id'))
    ),
    defaultTo([])
  )

const loadCollectionWith = (collectionName, withFn, records) =>
  over(
    lensProp(collectionName),
    concatWith(withFn, propOr([], collectionName, records))
  )

const loadFacetsTo = (toName, response) => {
  const facets = pipe(
    pathOr({}, ['facets', toName]),
    camelize
  )(response)

  return over(lensProp(toName), flip(mergeRight)(facets))
}

const loadRecords = response => state =>
  over(
    lensProp('records'),
    pipe(
      defaultTo({}),
      loadCollectionWith('assignments', identity, response),
      loadCollectionWith('cercaSets', identity, response),
      loadCollectionWith('institutions', identity, response),
      loadCollectionWith('institutionUsers', identity, response),
      loadCollectionWith('lessons', identity, response),
      loadCollectionWith('schoolClasses', identity, response),
      loadCollectionWith('schoolClassUsers', identity, response),
      loadCollectionWith('studentAssignments', identity, response),
      loadCollectionWith('studentGroups', identity, response),
      loadCollectionWith('users', identity, response)
    ),
    state
  )

const loadStandards = response => state =>
  over(
    lensPath(['records', 'standards']),
    pipe(
      defaultTo([]),
      propOr(__, 'standards', response)
    ),
    state
  )
const loadFacets = response => state =>
  over(
    lensProp('facets'),
    pipe(
      defaultTo({}),
      loadFacetsTo('assignments', response)
    ),
    state
  )

export const initialState = {
  assignments: [],
  institutions: [],
  institutionUsers: [],
  lessons: [],
  schoolClasses: [],
  schoolClassUsers: [],
  standards: [],
  studentAssignments: [],
  studentGroups: [],
  users: [],
}

export default (state = initialState, { response }) =>
  pipe(
    loadRecords(response),
    loadStandards(response),
    loadFacets(response)
  )(state)

export const TEACHER = 'Teacher'
export const INST_COACH = 'Instructional Coach'
export const SCHOOL_ADMIN = 'Principal'
export const DISTRICT_ADMIN = 'District Admin'

const roles = {
  TEACHER,
  INST_COACH,
  SCHOOL_ADMIN,
  DISTRICT_ADMIN,
}

export default roles

export const roleOptions = [
  { value: TEACHER, label: 'Teacher' },
  { value: INST_COACH, label: 'Instructional Coach' },
  { value: SCHOOL_ADMIN, label: 'School Administrator' },
  { value: DISTRICT_ADMIN, label: 'District Administrator' },
]

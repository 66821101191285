import createReducer from 'utils/create-reducer'

import ActionType from '../constants/actionTypes'
import steps from '../constants/steps'

import getNextStep from './stepsObject'

export const initialState = {
  currentStep: steps.START,
  data: {},
}

const handlers = {
  [ActionType.CHANGE_STEP]: changeStep,
  [ActionType.RESET]: reset,
}

// used for integration testing to shortcircuit state building
export const reducerWithInitialState = state => createReducer(state, handlers)

export default reducerWithInitialState(initialState)

export function reset(state, { payload: { errors } }) {
  return {
    ...state,
    currentStep: steps.ACCOUNT_USERPASS,
    errors,
  }
}

export function changeStep(state, { payload: { data = {}, currentStep } }) {
  const nextState = {
    ...state,
    currentStep: currentStep || getNextStep(state, data),
    data: { ...state.data, ...data },
  }

  return {
    ...nextState,
    isFinalStep: getNextStep(nextState, data) === steps.FINISH,
  }
}

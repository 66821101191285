import {
  __,
  always,
  append,
  assoc,
  cond,
  eqBy,
  equals,
  ifElse,
  is,
  lensPath,
  map,
  not,
  over,
  pipe,
  prepend,
  prop,
  set,
  split,
  type,
  when,
} from 'ramda'

import { InitialState } from './InitialState'
import deepMerge from 'utils/deep-merge'

const pathArray = pipe(
  when(eqBy(type, 'String'), split('.')),
  prepend('ui')
)

const getLens = pipe(
  pathArray,
  lensPath
)

const alreadySelected = value => prop(value)

const addValue = value => assoc(value, true)

const removeValue = value => assoc(value, false)

const addOrRemove = value =>
  ifElse(alreadySelected(value), removeValue(value), addValue(value))

const handleSelection = value =>
  ifElse(is(Object), addOrRemove(value), always({ [value]: true }))

export default (state = InitialState, { action, value, path }) =>
  cond([
    [
      always(equals('hideAllBenchmarkPlots', action)),
      over(lensPath(['ui', 'studentBenchmarkPlot']), map(assoc('show', false))),
    ],
    [
      always(equals('toggle', action)),
      over(lensPath(append(value, pathArray(path))), not),
    ],
    [always(equals('set', action)), over(getLens(path), deepMerge(__, value))],
    [always(equals('append', action)), over(getLens(path), append(value))],
    [
      always(equals('selectItem', action)),
      over(getLens(path), handleSelection(value)),
    ],
    [always(equals('clearSelection', action)), set(getLens(path), value)],
  ])(state)

import { initialState as classUsageReportInitialState } from 'reducers/classUsageReport'
import { initialState as loadRecordsInitialState } from 'reducers/loadRecords'
import { initialState as currentlyLoadingInitialState } from 'reducers/setLoading'
import { initialState as currentlyUpdatingInitialState } from 'reducers/setUpdating'
import { initialState as contextInitialState } from 'reducers/updateContext'

// Need to Refactor 'currentlyLoading'. Currently, the reducers handling
// cercaSets and lessons are almost entirely duplicates of each other
// and should be refactored into a single abstraction for handling records
// that needed to be infinitely loaded.

export const InitialState = {
  cercaSets: { count: 0 },
  classUsage: classUsageReportInitialState,
  ...contextInitialState,
  ...currentlyLoadingInitialState,
  ...currentlyUpdatingInitialState,
  currentPage: 0,
  facets: { assignments: {} },
  favoriteCercaSets: { count: 0 },
  favoriteLessonModules: { count: 0 },
  favoriteLessons: { count: 0 },
  insight: { performanceStrands: [] },
  lessons: { count: 0, href: null, hrefTime: null },
  lessonDetail: { href: null, hrefTime: null },
  lessonModules: { count: 0 },
  pages: 0,
  pendingAssignments: {},
  records: loadRecordsInitialState,
  search: { term: '' },
  ui: { studentBenchmarkPlot: {} },
}

import createActionTypes from 'utils/createActionTypes'

export default createActionTypes('classes', [
  'SHOW_PASSWORD_DIALOG',
  'HIDE_PASSWORD_DIALOG',
  'SHOW_QUICKSCORE_DIALOG',
  'HIDE_QUICKSCORE_DIALOG',
  'SHOW_COPY_LINK_DIALOG',
  'HIDE_COPY_LINK_DIALOG',
  'SHOW_DELETE_ASSIGNMENTS_DIALOG',
  'HIDE_DELETE_ASSIGNMENTS_DIALOG',
  'SHOW_PRINT_ASSIGNMENT_DIALOG',
  'HIDE_PRINT_ASSIGNMENT_DIALOG',
  'SHOW_DELETE_STUDENT_ASSIGNMENT_DIALOG',
  'HIDE_DELETE_STUDENT_ASSIGNMENT_DIALOG',
  'SHOW_RESET_MC_DIALOG',
  'HIDE_RESET_MC_DIALOG',
  'SHOW_COMPLETE_STUDENT_ASSIGNMENT_DIALOG',
  'HIDE_COMPLETE_STUDENT_ASSIGNMENT_DIALOG',
  'SHOW_MARK_GRADED_DIALOG',
  'HIDE_MARK_GRADED_DIALOG',
  'SHOW_MARK_GRADED_STUDENT_ASSIGNMENT_DIALOG',
  'HIDE_MARK_GRADED_STUDENT_ASSIGNMENT_DIALOG',
  'SHOW_MARK_SUBMITTED_DIALOG',
  'HIDE_MARK_SUBMITTED_DIALOG',
  'SHOW_RESET_GRADING_MODE_DIALOG',
  'HIDE_RESET_GRADING_MODE_DIALOG',
  'SHOW_MARK_SUBMITTED_STUDENT_ASSIGNMENT_DIALOG',
  'HIDE_MARK_SUBMITTED_STUDENT_ASSIGNMENT_DIALOG',
])

import { ifElse, is, keys, map, mergeRight, reduce } from 'ramda'

import camelCase from 'utils/camelCase'

const camelize = item => {
  if (is(String, item)) {
    return item
  }
  if (is(Array, item)) {
    return map(camelize, item)
  }

  return reduce(
    (acc, key) => {
      if (is(Object, item[key])) {
        return mergeRight(acc, { [camelCase(key)]: camelize(item[key]) })
      }
      return mergeRight(acc, { [camelCase(key)]: item[key] })
    },
    {},
    keys(item)
  )
}

export default ifElse(is(String), camelCase, camelize)

import { lensPath, reduce, view } from 'ramda'

import { InitialState } from './InitialState'
import setState from 'reducers/setState'

export default function resetState(state = InitialState, { stateKeys }) {
  return reduce(
    (_state, stateKey) => {
      const lens = lensPath(stateKey.split('.'))
      const value = view(lens, InitialState)

      return setState(_state, { stateKey, value })
    },
    state,
    stateKeys
  )
}

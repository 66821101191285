import createReducer from 'utils/create-reducer'
import {
  selectSchoolClassUser,
  selectLesson,
  addPendingAssignment,
  removePendingAssignment,
  setPendingAssignments,
  clearPendingAssignments,
} from './pendingAssignments'

import { actionTypes } from 'modules/assignment/constants'

const initialState = {
  assignmentMutationPending: false,
  valid: true,
}

const reducers = {
  [actionTypes.SELECT_LESSON]: selectLesson,
  [actionTypes.SELECT_SCHOOL_CLASS_USER]: selectSchoolClassUser,
  [actionTypes.SET_PENDING_ASSIGNMENTS]: setPendingAssignments,
  [actionTypes.ADD_PENDING_ASSIGNMENT]: addPendingAssignment,
  [actionTypes.SET_SELECTED_SCHOOL_CLASS]: setSelectedSchoolClass,
  [actionTypes.TOGGLE_ASSIGNMENT_MUTATION_STATUS]: toggleAssignmentMutationStatus,
  [actionTypes.CLEAR_PENDING_ASSIGNMENTS]: clearPendingAssignments,
  [actionTypes.REMOVE_PENDING_ASSIGNMENT]: removePendingAssignment,
}

// Initial state for testing
export const reducerWithInitialState = state => createReducer(state, reducers)

export function setSelectedSchoolClass(
  state,
  { payload: { selectedSchoolClass } }
) {
  return {
    ...state,
    selectedSchoolClass,
  }
}

export function toggleAssignmentMutationStatus(state) {
  return {
    ...state,
    assignmentMutationPending: !state.assignmentMutationPending,
  }
}

export default createReducer(initialState, reducers)

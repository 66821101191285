import * as actions from 'constants/action-types'

import createReducer from 'utils/create-reducer'
import fetchClassUsage from 'reducers/classUsageReport'
import loadRecords from 'reducers/loadRecords'
import resetState from 'reducers/resetState'
import setLoading from 'reducers/setLoading'
import setSaving from 'reducers/setSaving'
import setState from 'reducers/setState'
import setUpdating from 'reducers/setUpdating'
import updateContext from 'reducers/updateContext'
import updateUi from 'reducers/updateUi'
import { InitialState } from './InitialState'

const handlers = {
  [actions.CLASS_USAGE_FETCH]: fetchClassUsage,
  [actions.LOAD_RECORDS]: loadRecords,
  [actions.RESET_STATE]: resetState,
  [actions.SET_LOADING]: setLoading,
  [actions.SET_UPDATING]: setUpdating,
  [actions.SET_SAVING]: setSaving,
  [actions.SET_STATE]: setState,
  [actions.UPDATE_CONTEXT]: updateContext,
  [actions.UPDATE_UI]: updateUi,
}

export default createReducer(InitialState, handlers)

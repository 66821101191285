import 'babel-polyfill'
import 'whatwg-fetch'
import React from 'react'
import { render } from 'react-dom'

import theme from 'styles/theme'

import { client, store } from './store'
import 'styles/common.inline.scss'

const mount = () => {
  const ROOT = document.getElementById('App')

  if (!ROOT) {
    throw new Error('element [id=App] not found')
  }

  const props = JSON.parse(ROOT.getAttribute('data-props'))

  import('components/AppIndex')
    .then(result => result.default)
    .then(AppIndex => {
      render(
        <AppIndex client={client} store={store} theme={theme} {...props} />,
        ROOT
      )
    })
}

mount()

if (module.hot) {
  module.hot.accept('components/AppIndex', mount)
}

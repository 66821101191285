import { __, evolve } from 'ramda'

import deepMerge from 'utils/deep-merge'

export const initialState = {
  currentlyLoading: { lessons: false },
}

export default (state = initialState, { currentlyLoading }) =>
  evolve({
    currentlyLoading: deepMerge(__, currentlyLoading),
  })(state)

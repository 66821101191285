export const START = 'CHOOSE SIGN IN METHOD'
export const ACCOUNT_FULLNAME = 'ENTER NAME'
export const ACCOUNT_USERPASS = 'ENTER EMAIL AND PASSWORD'
export const UPGRADE_CODE = 'ENTER UPGRADE CODE'
export const ROLE = 'CHOOSE ROLE'
export const TEACHING_SCHOOL = ' CHOOSE SCHOOL'
export const TEACHING_DISTRICT = 'CHOOSE DISTRICT'
export const TEACHING_GRADES = 'CHOOSE GRADES'
export const TEACHING_SUBJECTS = 'CHOOSE SUBJECTS'
export const FINISH = 'FINISHED SIGN UP'

export const SSO = 'CHOOSE SINGLE-SIGN-ON'
export const VALID_CODE = 'UPGRADE CODE IS VALID'

const steps = {
  START,
  ACCOUNT_FULLNAME,
  ACCOUNT_USERPASS,
  UPGRADE_CODE,
  ROLE,
  TEACHING_SCHOOL,
  TEACHING_DISTRICT,
  TEACHING_GRADES,
  TEACHING_SUBJECTS,
  FINISH,
  SSO,
  VALID_CODE,
}

export default steps

export const timelineSteps = {
  [ACCOUNT_FULLNAME]: 'STEP1',
  [ACCOUNT_USERPASS]: 'STEP1',
  [UPGRADE_CODE]: 'STEP2',
  [ROLE]: 'STEP3',
  [TEACHING_SCHOOL]: 'STEP4',
  [TEACHING_DISTRICT]: 'STEP4',
  [TEACHING_GRADES]: 'STEP4',
  [TEACHING_SUBJECTS]: 'STEP4',
}

import {
  either,
  evolve,
  isEmpty,
  isNil,
  mergeRight,
  mergeWith,
  __,
} from 'ramda'

export const initialState = {
  context: {
    availableGrades: [],
    availableInstitutions: [],
    cercaSetId: null,
    grade: null,
    gradeLevel: null,
    institutionId: null,
    schoolClass: {},
    teacherId: null,
  },
}

export default (
  state = initialState,
  { json: { context }, replace = true }
) => {
  if (replace) {
    return evolve({ context: mergeRight(__, context) })(state)
  }

  return evolve({
    context: mergeWith(
      (existing, newer) => {
        if (either(isEmpty, isNil)(existing)) {
          return newer
        }
        return existing
      },
      __,
      context
    ),
  })(state)
}

import steps from '../constants/steps'
import roles from '../constants/roles'

export const stepsObject = {
  [steps.START]: () => steps.ACCOUNT_FULLNAME,
  [steps.ACCOUNT_FULLNAME]: () => steps.ACCOUNT_USERPASS,
  [steps.ACCOUNT_USERPASS]: () => steps.UPGRADE_CODE,
  [steps.SSO]: () => steps.UPGRADE_CODE,
  [steps.UPGRADE_CODE]: () => steps.ROLE,
  [steps.VALID_CODE]: role =>
    ({
      [roles.TEACHER]: steps.TEACHING_GRADES,
      [roles.INST_COACH]: steps.TEACHING_GRADES,
      [roles.SCHOOL_ADMIN]: steps.FINISH,
      [roles.DISTRICT_ADMIN]: steps.FINISH,
    }[role]),
  [steps.ROLE]: role =>
    ({
      [roles.TEACHER]: steps.TEACHING_SCHOOL,
      [roles.INST_COACH]: steps.TEACHING_SCHOOL,
      [roles.SCHOOL_ADMIN]: steps.TEACHING_SCHOOL,
      [roles.DISTRICT_ADMIN]: steps.TEACHING_DISTRICT,
    }[role]),
  [steps.TEACHING_SCHOOL]: role =>
    ({
      [roles.TEACHER]: steps.TEACHING_GRADES,
      [roles.INST_COACH]: steps.TEACHING_GRADES,
      [roles.SCHOOL_ADMIN]: steps.FINISH,
    }[role]),
  [steps.TEACHING_GRADES]: () => steps.TEACHING_SUBJECTS,
  [steps.TEACHING_SUBJECTS]: () => steps.FINISH,
  [steps.TEACHING_DISTRICT]: () => steps.FINISH,
  [steps.FINISH]: () => steps.FINISH,
}

// eslint-disable-next-line complexity
export default (state, data) => {
  const role = data.role || (state.data && state.data.role) || roles.TEACHER
  let currentStep = state.currentStep || steps.START

  if (state.currentStep === steps.START && data.provider) {
    currentStep = steps.SSO
  } else if (state.currentStep === steps.ROLE && state.data.institutionCode) {
    currentStep = steps.VALID_CODE
  }

  return stepsObject[currentStep](role)
}

import createReducer from 'utils/create-reducer'

import ActionTypes from '../constants/actionTypes'

export const initialState = {
  copyLinkDialog: { show: false },
  quickscoreDialog: { show: false },
  deleteAssignmentsDialog: { show: false },
  printAssignmentDialog: { show: false },
  deleteStudentAssignmentDialog: { show: false },
  resetMCDialog: { show: false },
  markGradedDialog: { show: false },
  markGradedStudentAssignmentDialog: { show: false },
  markSubmittedDialog: { show: false },
  markSubmittedStudentAssignmentDialog: { show: false },
  resetGradingModeDialog: { show: false },
}

export const handlers = {
  [ActionTypes.SHOW_QUICKSCORE_DIALOG]: showQuickscoreDialog,
  [ActionTypes.HIDE_QUICKSCORE_DIALOG]: hideQuickscoreDialog,
  [ActionTypes.SHOW_COPY_LINK_DIALOG]: showCopyLinkDialog,
  [ActionTypes.HIDE_COPY_LINK_DIALOG]: hideCopyLinkDialog,
  [ActionTypes.SHOW_PRINT_ASSIGNMENT_DIALOG]: showPrintAssignmentDialog,
  [ActionTypes.HIDE_PRINT_ASSIGNMENT_DIALOG]: hidePrintAssignmentDialog,
  [ActionTypes.SHOW_DELETE_ASSIGNMENTS_DIALOG]: showDeleteAssignmentsDialog,
  [ActionTypes.HIDE_DELETE_ASSIGNMENTS_DIALOG]: hideDeleteAssignmentsDialog,
  [ActionTypes.SHOW_DELETE_STUDENT_ASSIGNMENT_DIALOG]: showDeleteStudentAssignmentDialog,
  [ActionTypes.HIDE_DELETE_STUDENT_ASSIGNMENT_DIALOG]: hideDeleteStudentAssignmentDialog,
  [ActionTypes.SHOW_RESET_MC_DIALOG]: showResetMCDialog,
  [ActionTypes.HIDE_RESET_MC_DIALOG]: hideResetMCDialog,
  [ActionTypes.SHOW_MARK_GRADED_DIALOG]: showMarkGradedDialog,
  [ActionTypes.HIDE_MARK_GRADED_DIALOG]: hideMarkGradedDialog,
  [ActionTypes.SHOW_MARK_GRADED_STUDENT_ASSIGNMENT_DIALOG]: showMarkGradedStudentAssignmentDialog,
  [ActionTypes.HIDE_MARK_GRADED_STUDENT_ASSIGNMENT_DIALOG]: hideMarkGradedStudentAssignmentDialog,
  [ActionTypes.SHOW_MARK_SUBMITTED_DIALOG]: showMarkSubmittedDialog,
  [ActionTypes.HIDE_MARK_SUBMITTED_DIALOG]: hideMarkSubmittedDialog,
  [ActionTypes.SHOW_RESET_GRADING_MODE_DIALOG]: showResetGradingModeDialog,
  [ActionTypes.HIDE_RESET_GRADING_MODE_DIALOG]: hideResetGradingModeDialog,
  [ActionTypes.SHOW_MARK_SUBMITTED_STUDENT_ASSIGNMENT_DIALOG]: showMarkSubmittedStudentAssignmentDialog,
  [ActionTypes.HIDE_MARK_SUBMITTED_STUDENT_ASSIGNMENT_DIALOG]: hideMarkSubmittedStudentAssignmentDialog,
}

export default createReducer(initialState, handlers)
// Initial state for testing
export const reducerWithInitialState = state => createReducer(state, handlers)

export function showQuickscoreDialog(
  state,
  { payload: { assignmentId, studentAssignment } }
) {
  return {
    ...state,
    quickscoreDialog: {
      show: true,
      assignmentId,
      studentAssignment,
    },
  }
}

export function hideQuickscoreDialog(state) {
  return { ...state, quickscoreDialog: { show: false } }
}

export function showCopyLinkDialog(state, { payload: { assignments } }) {
  return {
    ...state,
    copyLinkDialog: {
      show: true,
      assignments,
    },
  }
}

export function hideCopyLinkDialog(state) {
  return { ...state, copyLinkDialog: { show: false } }
}

export function showPrintAssignmentDialog(
  state,
  { payload: { id, isStudentAssignment } }
) {
  return {
    ...state,
    printAssignmentDialog: {
      show: true,
      id,
      isStudentAssignment,
    },
  }
}

export function hidePrintAssignmentDialog(state) {
  return { ...state, printAssignmentDialog: { show: false } }
}

export function showDeleteAssignmentsDialog(
  state,
  { payload: { assignmentId, inProgressAssignmentsCount } }
) {
  return {
    ...state,
    deleteAssignmentsDialog: {
      show: true,
      assignmentId,
      inProgressAssignmentsCount,
    },
  }
}

export function hideDeleteAssignmentsDialog(state) {
  return { ...state, deleteAssignmentsDialog: { show: false } }
}

export function showDeleteStudentAssignmentDialog(
  state,
  { payload: { studentAssignmentId } }
) {
  return {
    ...state,
    deleteStudentAssignmentDialog: {
      show: true,
      studentAssignmentId,
    },
  }
}

export function hideDeleteStudentAssignmentDialog(state) {
  return { ...state, deleteStudentAssignmentDialog: { show: false } }
}

export function showResetMCDialog(state, { payload: { id, transitionType } }) {
  return {
    ...state,
    studentAssignment: { id, transitionType },
    resetMCDialog: {
      show: true,
    },
  }
}

export function hideResetMCDialog(state) {
  return { ...state, studentAssignment: {}, resetMCDialog: { show: false } }
}

export function showMarkGradedDialog(state) {
  return {
    ...state,
    markGradedDialog: { show: true },
  }
}

export function hideMarkGradedDialog(state) {
  return {
    ...state,
    markGradedDialog: { show: false },
  }
}

export function showMarkGradedStudentAssignmentDialog(
  state,
  { payload: { id, transitionType } }
) {
  return {
    ...state,
    markGradedStudentAssignmentDialog: { show: true },
    studentAssignment: { id, transitionType },
  }
}

export function hideMarkGradedStudentAssignmentDialog(state) {
  return {
    ...state,
    markGradedStudentAssignmentDialog: { show: false },
    studentAssignment: {},
  }
}

export function showMarkSubmittedDialog(state) {
  return {
    ...state,
    markSubmittedDialog: { show: true },
  }
}

export function hideMarkSubmittedDialog(state) {
  return {
    ...state,
    markSubmittedDialog: { show: false },
  }
}

export function showResetGradingModeDialog(state) {
  return {
    ...state,
    resetGradingModeDialog: { show: true },
  }
}

export function hideResetGradingModeDialog(state) {
  return {
    ...state,
    resetGradingModeDialog: { show: false },
  }
}

export function showMarkSubmittedStudentAssignmentDialog(
  state,
  { payload: { id, transitionType } }
) {
  return {
    ...state,
    markSubmittedStudentAssignmentDialog: { show: true },
    studentAssignment: { id, transitionType },
  }
}

export function hideMarkSubmittedStudentAssignmentDialog(state) {
  return {
    ...state,
    markSubmittedStudentAssignmentDialog: { show: false },
    studentAssignment: {},
  }
}

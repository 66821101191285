import { mergeRight } from 'ramda'
import createReducer from 'utils/create-reducer'
import actionTypes from 'modules/WritingLesson/constants/actionTypes'

const initialState = {}

const setStepMap = (state, { payload: { stepMap } }) =>
  mergeRight(state, { stepMap })

const reducers = {
  [actionTypes.SET_STEP_MAP]: setStepMap,
}

export default createReducer(initialState, reducers)

export const ALL_STUDENTS_OPTION_VALUE = 'ALL'
export const ENTIRE_CLASS_ASSIGNED_TO_TYPE = 'SchoolClass'
export const actionTypes = {
  SELECT_LESSON: 'SELECT_LESSON',
  SELECT_SCHOOL_CLASS_USER: 'SELECT_SCHOOL_CLASS_USER',
  SET_PENDING_ASSIGNMENTS: 'SET_PENDING_ASSIGNMENTS',
  ADD_PENDING_ASSIGNMENT: 'ADD_PENDING_ASSIGNMENT',
  SET_SELECTED_SCHOOL_CLASS: 'SET_SELECTED_SCHOOL_CLASS',
  TOGGLE_ASSIGNMENT_MUTATION_STATUS: 'TOGGLE_ASSIGNMENT_MUTATION_STATUS',
  CLEAR_PENDING_ASSIGNMENTS: 'CLEAR_PENDING_ASSIGNMENTS',
  REMOVE_PENDING_ASSIGNMENT: 'REMOVE_PENDING_ASSIGNMENT',
}

export const NO_STUDENT_SELECTED_COPY =
  'Looks like there\'s a level selected with no students. Add students to that level or click the "X" to remove it.'

export const SELECTED_LESSONS_PATH = 'ui.AssignDialog.selectedLessons'

export const ALL_STUDENT_OPTION = {
  user: { fullName: 'All Students' },
  id: ALL_STUDENTS_OPTION_VALUE,
}

export const ASSIGNMENT_DETAIL_PAGE_ROUTE =
  '/teacher/classes/:schoolClassId/assignments/:assignmentId'

import {
  any,
  both,
  compose,
  values,
  prop,
  always,
  ifElse,
  curry,
  mergeDeepRight,
  dissocPath,
} from 'ramda'
import emptyOrNil from 'utils/emptyOrNil'
import { NO_STUDENT_SELECTED_COPY } from 'modules/assignment/constants'

// Using timestamped version of uuid for sorting order in SelectorGroups
import uuid from 'uuid/v1'

const getErrors = ifElse(
  any(
    both(
      compose(
        emptyOrNil,
        prop('schoolClassUserIds')
      ),
      prop('touched')
    )
  ),
  always(NO_STUDENT_SELECTED_COPY),
  always('')
)

const mergePendingAssignmentState = curry((handler, state, { payload }) => {
  const pendingAssignments = mergeDeepRight(
    state.pendingAssignments,
    handler(payload)
  )
  const errors = getErrors(values(pendingAssignments))

  return {
    ...state,
    errors,
    pendingAssignments,
  }
})

export const selectSchoolClassUser = mergePendingAssignmentState(
  ({ schoolClassUserIds, id }) => ({
    [id]: { id, schoolClassUserIds, touched: true },
  })
)

export const selectLesson = mergePendingAssignmentState(({ lessonId, id }) => ({
  [id]: { id, lessonId, touched: true },
}))

export const addPendingAssignment = mergePendingAssignmentState(
  ({ lessonId }) => {
    const id = uuid()

    return { [id]: { id, lessonId, schoolClassUserIds: [] } }
  }
)

export const removePendingAssignment = (state, { payload: { id } }) =>
  dissocPath(['pendingAssignments', id], state)

export function clearPendingAssignments(state) {
  return {
    ...state,
    pendingAssignments: {},
  }
}

export function setPendingAssignments(
  state,
  { payload: { pendingAssignments } }
) {
  const errors = getErrors(values(pendingAssignments))

  return {
    ...state,
    pendingAssignments,
    errors,
    valid: emptyOrNil(errors),
  }
}

import { combineReducers } from 'redux'

import registration from 'modules/registration/reducer'
import classes from 'modules/classes/reducer'
import curriculum from 'modules/assignment/reducer'
import writingLesson from 'modules/WritingLesson/reducer'

import app from './app'

export default (reducers = {}) =>
  combineReducers({
    app,
    registration,
    classes,
    curriculum,
    writingLesson,
    ...reducers,
  })
